<template>
	<div style="background-color:#F6F6F6">
		<!-- height:calc(100vh - 94px - 30px); -->
		<!-- style="background: #F6F6F6;" -->
		<el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumbClass">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>账户管理</el-breadcrumb-item>
		</el-breadcrumb>
		<headtop :activeIndex="activeIndex" :title="titlename" :ifxianshi="false"></headtop>
		<div class="mainTop">
			<div class="mainTop-headerDiv">
				<!-- <img src="../assets/recharge/headerImg.png" class="headerImg"> -->

				<img :src="headerImg" class="headerImg">

				<div class="personDiv">
					<div>{{ username }}，欢迎您！</div>
					<div>{{ getCookie("customername") }}</div>
				</div>
			</div>
			<!-- <div>收支明细</div> -->
			<!-- <div class="mainTop-moneyDiv">
				<div class="mainTop-moneyDiv-titleDiv">可用额度<img src="../assets/recharge/question (2).png"></div>
				<div class="mainTop-moneyDiv-detailDiv">
					<div>￥{{money}}</div>

				</div>
			</div> -->
		</div>
		<div class="mainBottom">
			<div class="mainBottom-titleDiv">
				<div>充值</div>
			</div>
			<div class="mainBottom-contentDiv">

				<el-radio-group v-model="radio1" @change="onRadioChange" class="radioDiv">
					<el-radio :label="item.serve_id" :key="item.serve_id" border v-for="item in companyList">
						{{ item.name }}<span class="companySpan">可用余额&nbsp;:<span style="margin-left:10px">￥{{ item.blance
						}}元</span></span>
					</el-radio>
				</el-radio-group>

				<div v-for="item in tableValue" v-show="serve_id == 7" :key="item.EnterpriseAuthentication"
					class="tableClass">
					<div>
						<!-- <img src="../assets/index/ContractSigning.png">合同签订&nbsp;:&nbsp;<span>￥{{item.ContractSign}}</span> -->
						<img :src="ContractSigning">合同签订&nbsp;:&nbsp;<span>￥{{ item.ContractSign }}</span>

					</div>
					<div style="margin-left: 20px;">
						<!-- <img src="../assets/index/companySigning.png"> -->
						<img :src="companySigning">

						企业认证&nbsp;:<span>￥{{ item.EnterpriseAuthentication }}</span>
					</div>
					<div style="margin-left: 20px;">
						<!-- <img src="../assets/index/personSigning.png"> -->
						<img :src="personSigning">

						个人认证&nbsp;:<span>￥{{ item.PersonalAuthentication }}</span>
					</div>
				</div>



				<el-radio-group v-model="radio2" @change="onRadioChangeTwo" class="radioDivTwo">
					<el-radio :label="1" :key="1">
						线上充值
					</el-radio>
					<el-radio :label="2" :key="2">
						线下充值
					</el-radio>
				</el-radio-group>
				<!-- 	<div class="mainBottom-contentDiv-currentBalanceView">
					<div>当前余额：</div>
					<div>￥{{moneyCompany}}</div>
				</div> -->
				<div class="mainBottom-contentDiv-rechargeBalance" v-if="radio2 == 1">
					<div>充值余额：</div>
					<el-input type="" v-model="amount">
						<template slot="prepend">￥</template>
					</el-input>
				</div>
				<div class="mainBottom-contentDiv-rechargeBalance" style="marginTop:30px" v-if="radio2 == 1">
					<div style="width: 91px;">备注：</div>
					<el-input class="mainBottom-contentDiv-rechargeBalance-textarea" type="textarea" resize="horizontal"
						v-model="remark"></el-input>
				</div>
				<div v-if="account_name && radio2 == 2" class="accountNumberFDiv" style="margin-bottom: 200px;">
					<div class="accountNumber" v-if="radio2 == 2">
						<div>开户名称:</div>
						<div>{{ account_name }}</div>
					</div>
					<div class="accountNumber" v-if="bank_number && radio2 == 2">
						<div>开户银行:</div>

						<img src="../assets/index/GuangfaBank.png" class="bankImage"
							v-if="account_bank.indexOf('广发银行') != -1">
						<img src="../assets/index/shanghaiPudongBank.png" class="bankImage"
							v-if="account_bank.indexOf('浦发银行') != -1">
						<img src="../assets/index/economicConstructionBank.png" class="bankImage"
							v-if="account_bank.indexOf('建设银行') != -1">
						<img src="../assets/index/agriculturalBank.png" class="bankImage"
							v-if="account_bank.indexOf('农业银行') != -1">
						<img src="../assets/index/chinaBank.png" class="bankImage"
							v-if="account_bank.indexOf('中国银行') != -1">
						<img src="../assets/index/chinaCITICBank.png" class="bankImage"
							v-if="account_bank.indexOf('中信银行') != -1">
						<img src="../assets/index/chinaMerchantsBank.png" class="bankImage"
							v-if="account_bank.indexOf('招商银行') != -1">
						<img src="../assets/index/communicationsBank.png" class="bankImage"
							v-if="account_bank.indexOf('交通银行') != -1">
						<img src="../assets/index/economicConstructionBank.png" class="bankImage"
							v-if="account_bank.indexOf('中国建设银行') != -1">
						<img src="../assets/index/everbrightBank.png" class="bankImage"
							v-if="account_bank.indexOf('中国光大银行') != -1">
						<img src="../assets/index/GuangfaBank.png" class="bankImage"
							v-if="account_bank.indexOf('广发银行') != -1">
						<img src="../assets/index/huaxiaBank.png" class="bankImage"
							v-if="account_bank.indexOf('华夏银行') != -1">
						<img src="../assets/index/industrialBank.png" class="bankImage"
							v-if="account_bank.indexOf('兴业银行') != -1">
						<img src="../assets/index/IndustrialCommercialBank.png" class="bankImage"
							v-if="account_bank.indexOf('中国工商银行') != -1">
						<img src="../assets/index/PeopleLivelihoodBank.png" class="bankImage"
							v-if="account_bank.indexOf('中国民生银行') != -1">
						<img src="../assets/index/pinganyinhang_xin.png" class="bankImage"
							v-if="account_bank.indexOf('平安银行') != -1">
						<img src="../assets/index/shanghaiPudongBank.png" class="bankImage"
							v-if="account_bank.indexOf('浦发银行') != -1">

						<div class="bankView">
							<div>{{ account_bank }}</div>
							<div class="bankView-smallView" style="margin-left: 0;">
								<div>联行号:</div>
								<div>{{ bank_number }}</div>
							</div>
						</div>

					</div>
					<div class="accountNumber" v-if="downAccount_code && radio2 == 2" style="margin-left: 107px;">
						<div>专属汇款账号:</div>
						<div class="accountNumberView">{{ downAccount_code }}</div>
					</div>
					<!-- <div class="accountNumber" v-if="radio2==2">
						<div>联行号:</div>
						<div>{{bank_number}}</div>
					</div> -->

					<div class="accountNumber" v-if="radio2 == 2">
						<div style="margin-left: 29px;">余额:</div>
						<div class="blanceClass">￥{{ blance }}</div>
					</div>
				</div>

				<div v-else v-show="radio2 == 2" class="accountNumberFDivNew">
					<img src="../assets/index/noHaveBankImage.png" class="noHaveBankImage">
				</div>

				<!-- "account_name": "开户名称", //开户名称
				"account_bank": "开户银行", //开户银行
				"account_code": "专属汇款账号", //专属汇款账号
				"bank_number": "联行号", //联行号
				"blance": 238231.35 //余额

				this.account_name = this.companyList[i].account_name
				this.account_bank = this.companyList[i].account_bank
				this.downAccount_code = this.companyList[i].account_code
				this.bank_number = this.companyList[i].bank_number
				this.blance = this.companyList[i].blance -->






				<!-- 	<textarea placeholder="温馨提示" class="textareaClass"></textarea>


				<div class="understoodDiv">
					<el-checkbox class="checkboxClass" v-model="iKnowValue"></el-checkbox>
					<div class="iKonwDiv">我已了解：划款的款项只可用...(具体开发根据实际需要修改内容，只是样式)</div>
				</div> -->


				<!-- 	<el-radio v-model="radio1" v-for="item in companylist" :key="item.serve_id" label="item.serve_id"
						border>
						{{item.name}}
					</el-radio>
					<el-radio v-model="radio1" label="2" border>法大大</el-radio>
					<el-radio v-model="radio1" label="2" border>平台</el-radio> -->
				<!-- <div class="textareaDiv">{{textarea}}</div> -->


				<!-- <el-input class="textareaDiv" type="textarea" placeholder="请输入内容" disabled="disabled" 
					show-word-limit>
				</el-input> -->

				<!-- <div class="knowDiv">
					<el-checkbox v-model="checked">我已了解：充值的款项只可用...(具体开发根据实际需要修改内容，只是样式)</el-checkbox>
				</div> -->
				<div class="buttonDiv" @click="submitFun" v-if="radio2 == 1">
					充值
				</div>
			</div>
		</div>
		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
			<span>您是否充值完毕</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="moneyTrue">充值成功</el-button>
				<el-button type="primary" @click="dialogVisible = false">取消充值</el-button>
			</span>
		</el-dialog>


		<div class="copyright" style="position:fixed;bottom:0;width:100vw">Copyright©2021 -2025 热付通SASS平台</div>



	</div>
</template>


<script>
import headtop from "../components/moneyHeader.vue";
// import config from "../request/config"

// import baseImgUrl from "../request/config.js"
import config from "../request/config"


import {
	getCompanyList
} from "../request/api";

// import {
// 	getproduct
// } from "../request/api";
export default {
	inject: ['reload'],
	name: "Resgin",
	components: {
		headtop,
	},
	data() {
		return {
			headerImg: config.baseImgUrl + '/public/static/sass/assets/recharge/headerImg.png',
			ContractSigning: config.baseImgUrl + '/public/static/sass/assets/index/ContractSigning.png',
			companySigning: config.baseImgUrl + '/public/static/sass/assets/index/companySigning.png',

			personSigning: config.baseImgUrl + '/public/static/sass/assets/index/personSigning.png',

			downAccount_code: "",
			// iKnowValue: "",
			radio2: "",
			username: "",
			customername: "",
			moneyCompany: 0,
			order_id: "",
			textarea: "“热速签”是一款即时、便捷、合规的线上签章平台，基于各类证明签约真实场景，极大杜绝签章纠纷，足不出户即可实现线上签约，为企业节省管理成本，为用户提升办事效率。",
			money: '',
			checkboxGroup1: [],
			radio1: true,
			checklable: "",
			activeIndex: "0",
			dialogVisible: false,
			amount: '',
			remark: '',
			centerDialogVisible: false,
			ischoseid: -1,
			companyList: [],
			currentPage1: 5,
			currentPage2: 5,
			currentPage3: 5,
			serve_id: "",
			currentPage4: 4,
			titlename: "热付通SAAS平台",
			tableValue: "",
			account_name: "",
			account_bank: "",
			bank_number: "",
			blance: "",



		};
	},
	beforeCreate() {
		// console.log("为撒没有啊", this.getCookie("customername"))
		// document
		// 	.querySelector("html")
		// 	.setAttribute("style", "background-color:#F6F9FF;");
	},
	beforeDestroy() {
		document.querySelector("html").removeAttribute("style");
	},
	mounted() {
		if (this.getCookie("username")) {
			this.username = this.getCookie("username");
			this.customername = this.getCookie("customername")
			console.log("this.customername", this.customername)
		}
	},
	created() {
		this.getmoney()
		this.getCompanyListFun()
	},
	methods: {
		getMoneyCompany() {
			var data = {
				func: "QueryAccountInfo",
				ctrl: "FadadaApi",
				// customer_id: "",
				customer_id: this.serve_id,
			};
			console.log("传递的参数", data)
			getCompanyList(data).then((res) => {
				if (res.code == 200) {
					// this.ifused = res.data;
					console.log("看看返回值11111", res.data)
					// window.location.href = res.data.pay_url
					this.moneyCompany = res.data.M_Balance
					// this.dialogVisible = false
				} else {
					console.log("看看错误返回值", res)
				}
			})
		},
		onRadioChange(e) {
			console.log("看看这个是什么", e)
			this.serve_id = e
			for (var i = 0; i < this.companyList.length; i++) {
				if (e == this.companyList[i].serve_id) {

					// "account_name": "开户名称", //开户名称
					// "account_bank": "开户银行", //开户银行
					// "account_code": "专属汇款账号", //专属汇款账号
					// "bank_number": "联行号", //联行号
					// "blance": 238231.35 //余额

					this.account_name = this.companyList[i].account_name
					this.account_bank = this.companyList[i].account_bank
					this.downAccount_code = this.companyList[i].account_code
					this.bank_number = this.companyList[i].bank_number
					this.blance = this.companyList[i].blance
					console.log("循环后是什么", this.companyList[i])
					console.log("复制后", this.downAccount_code)
				}
			}
			var dataOne = {
				func: "queryConfigList",
				ctrl: "Configuration",
				sign: "fadada",
				// customer_id: "",
				customer_id: this.serve_id,
			};
			console.log("传递的参数", dataOne)
			getCompanyList(dataOne).then((res) => {
				if (res.code == 200) {
					this.tableValue = res.data
				} else {
					console.log("看看错误返回值", res)
				}
			})
			this.getMoneyCompany()
		},
		onRadioChangeTwo(e) {
			console.log("看看这个是什么", e)
			// this.serve_id = e
			this.getMoneyCompany()
		},
		moneyTrue() {
			var data1 = {
				func: "queryOrder",
				ctrl: "customerBalance",
				// customer_id: "",
				order_id: this.order_id,
			};
			console.log("传递的参数", data1)
			getCompanyList(data1).then((res) => {
				if (res.code == 200) {
					var data = {
						func: "balance",
						ctrl: "customerBalance",
						// customer_id: "",
						customer_id: this.serve_id,
					};
					console.log("传递的参数", data)
					getCompanyList(data).then((res) => {
						if (res.code == 200) {
							// this.ifused = res.data;
							console.log("看看返回值", res.data)
							// window.location.href = res.data.pay_url
							this.money = res.data
							this.dialogVisible = false
						} else {
							console.log("看看错误返回值", res)
						}
					})

					var dataOne = {
						func: "QueryAccountInfo",
						ctrl: "FadadaApi",
						// customer_id: "",
						customer_id: this.serve_id,
					};
					console.log("传递的参数", dataOne)
					getCompanyList(dataOne).then((res) => {
						if (res.code == 200) {
							// this.ifused = res.data;
							console.log("看看返回值11111", res.data)
							// window.location.href = res.data.pay_url
							this.moneyCompany = res.data.M_Balance
							// this.dialogVisible = false
							this.reload();

						} else {
							console.log("看看错误返回值", res)
						}
					})




				} else {
					console.log("看看错误返回值", res)
				}
			})






		},

		submitFun() {

			if (this.amount < 50) {
				this.$message({
					message: "充值金额不能少于50",
					type: "error",
					offset: 100,
				});
				return
			}

			console.log("这个是什么东西", this.iKnowValue)
			// if (this.iKnowValue) {
			if (!this.serve_id) {
				this.$message({
					message: "请选择充值公司",
					type: "error",
					offset: 100,
				});
				return

			}
			if (!this.amount) {
				this.$message({
					message: "请填写充值余额",
					type: "error",
					offset: 100,
				});
				return
			}
			var dataTw = {
				func: "addSorder",
				ctrl: "CustomerBalance",
				// customer_id: "",
				serve_id: this.serve_id,
				amount: this.amount, remark: this.remark
			};
			console.log("传递的参数", dataTw)
			getCompanyList(dataTw).then((res) => {
				if (res.code == 200) {
					// this.ifused = res.data;
					console.log("看看返回值", res.data)
					// window.location.href = res.data.pay_url
					this.order_id = res.data.order_id
					this.dialogVisible = true
					window.open(res.data.pay_url, "_blank")
				} else {
					console.log("看看错误返回值", res)
				}
			})
			// } else {
			// 	this.$message({
			// 		message: "请选中了解",
			// 		type: "error",
			// 		offset: 100,
			// 	});
			// }





		},


		getmoney() {
			// setInterval(function() {
			var data = {
				// func: "QueryAccountInfo",
				// ctrl: "FadadaApi",

				// customer_id: this.customerid,
				func: "balance",
				ctrl: "customerBalance",
				// customer_id: "",
				customer_id: this.serve_id,
			};
			console.log("看看传递的数据1111", data)
			getCompanyList(data).then((res) => {
				if (res.code == 200) {
					// this.ifused = res.data;

					this.money = res.data
					if (!this.money) {
						this.money = 0
					}

					console.log("看看返回值", this.companyList)
				} else {
					console.log("看看错误返回值", res)
				}
			})
			// }), 1000)
		},
		getCompanyListFun() {
			// setInterval(function() {
			var data = {
				func: "payList",
				ctrl: "Serve",
			};
			getCompanyList(data).then((res) => {
				if (res.code == 200) {
					// this.ifused = res.data;

					this.companyList = res.data
					console.log("看看返回值", this.companyList)
				} else {
					console.log("看看错误返回值", res)
				}
			})
			// }), 1000)
		},
		gotonext() {
			if (this.getCookie("customerid")) {
				this.centerDialogVisible = false;
			} else {
				this.$message({
					message: "请选择公司",
					type: "warning",
					offset: 100,
				});
			}
		},
		//选择公司啊
		chosecompagy(index, name, id) {
			this.ischoseid = index;

			this.setCookie("customerid", id);
			this.setCookie("customername", name);

		},

		//下一步
		gonext() {
			this.$router.push({
				name: "submit",
				params: {
					checkboxGroup: this.checkboxGroup1
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
	background-color: rgba(49, 91, 210, 1);
	border-color: rgba(49, 91, 210, 1);

}

/deep/.el-checkbox {
	width: 100%;
}

/deep/.el-checkbox__input {
	position: absolute;
}

/deep/.el-checkbox__label {
	display: block;
	margin-top: 30px;
}

.biaoti {
	text-align: center;
	color: rgba(102, 102, 102, 1);
	font-weight: 400;
	margin-top: 0;
	margin-bottom: 50px;
}

.list {
	width: 75%;
	margin: 164px 12.5%;
}

.nextbutton {
	display: block;
	margin: auto;
	width: 350px;
	background: rgba(49, 91, 210, 1);
	color: #fff;
	font-size: 20px;
}

.el-badge {
	position: absolute;
	right: 15px;
	top: 15px;
}

.mainDiv {
	width: 1428px;
	// height: 814px;
	height: auto;
	background: #fff;
	margin: 0 auto;
	padding-bottom: 57px;
	box-sizing: border-box;
}

.el-table td.el-table__cell {
	font-weight: bold;
}

.mainTop {
	width: 1428px;
	height: 256px;
	background: #FFFFFF;
	margin: 0 auto;
	padding-top: 6px;
	padding-left: 8px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 100px;

}

.headerImg {
	width: 102px;
	height: 105px;
}

.personDiv {
	margin-left: 35px;
}

.personDiv div:nth-child(1) {
	font-size: 24px;
	font-weight: bold;
	color: #F36C09;
	// line-height: 30px;
	padding-top: 29px;
}

.personDiv div:nth-child(2) {
	font-size: 14px;
	font-weight: bold;
	color: #1E1E1E;
	margin-top: 16px;
	// line-height: 30px;
}

.mainTop-headerDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.mainTop-moneyDiv {
	margin-top: 39px;
	padding-left: 9px;

}

.mainTop-moneyDiv-detailDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 10px;
}

.mainTop-moneyDiv-detailDiv div:nth-child(1) {
	font-size: 24px;
	font-weight: bold;
	color: #F36C09;
}

.mainTop-moneyDiv-detailDiv div:nth-child(2) {
	width: 98px;
	height: 29px;
	background: rgba(32, 134, 238, 0);
	border: 1px solid #2086EE;
	font-size: 14px;
	font-weight: 300;
	color: #2086EE;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
}

.mainTop-moneyDiv-titleDiv {
	font-size: 16px;
	font-weight: bold;
	color: #010101;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

}

.mainTop-moneyDiv-titleDiv img {
	width: 15px;
	height: 15px;
	margin-left: 9px;
}

.mainBottom {
	width: 1430px;
	height: 606px;
	background: #FFFFFF;
	margin: 0 auto;
	margin-top: 23px;
	padding-bottom: 79px;
	padding-left: 40px;
	box-sizing: border-box;

}

.mainBottom-titleDiv {
	width: 100%;
	height: 64px;
	border-bottom: 1px solid #E4ECEF;
}

.mainBottom-titleDiv div {
	height: 100%;
	line-height: 100%;
	font-size: 14px;
	font-weight: bold;
	color: #2086EE;
	border-bottom: 2px solid #2086EE;
	width: 96px;
	// margin-left: 41px;
	padding-top: 31px;
	box-sizing: border-box;
	text-align: center;
}

.mainBottom-contentDiv {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	// padding-left: 40px;
	box-sizing: border-box;

	// padding-top: 50px;
}

.mainBottom-contentDiv-currentBalanceView {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 30px;
}

.mainBottom-contentDiv-currentBalanceView div:nth-child(1) {
	font-size: 14px;
	font-weight: bold;
	color: #000000;
}

.mainBottom-contentDiv-currentBalanceView div:nth-child(2) {
	font-size: 24px;
	font-weight: bold;
	color: #F36C09;
}

.mainBottom-contentDiv-rechargeBalance {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	font-size: 14px;
	font-weight: bold;
	color: #000000;
	// margin-top: 30px;

}

.mainBottom-contentDiv-rechargeBalance div:nth-child(1) {
	width: 120px;
}

.mainBottom-contentDiv-rechargeBalance-textarea {
	width: 286px;
}

.radioDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 30px;
}

.radioDiv label {
	width: auto;
	height: 60px;
	background: rgba(255, 255, 255, 0);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	margin: 0;
	padding-left: 21px;
	box-sizing: border-box;
	padding-right: 30px;
	// border: 1px solid #2086EE;
}

// .textareaDiv {
// 	margin-top: 30px;

// }

// .textareaDiv /deep/.el-textarea__inner {
// 	width: 1369px;
// 	height: 98px;
// 	background: #F4F4F4;
// 	border: 1px solid #EAEAEA;
// }

.buttonDiv {
	width: 120px;
	height: 40px;
	background: #2086EE;
	font-size: 16px;
	font-weight: 300;
	color: #FFFFFF;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 60px;

}

.knowDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin-top: 23px;

}

.knowDiv /deep/.el-checkbox {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100% !important;
}

.knowDiv /deep/.el-checkbox__label {
	font-size: 14px;
	font-weight: 300;
	color: #525252;
	line-height: 30px;
	margin: 0;
	margin-left: 16px;
	line-height: 14px;
}

.radioDivTwo {
	width: 100px;
	height: 60px;
	background: rgba(255, 255, 255, 0);
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	margin: 0;
}

// .textareaClass {
// 	width: 1369px;
// 	height: 98px;
// 	max-width: 1369px;
// 	max-height: 98px;
// 	background: #F4F4F4;
// 	border: 1px solid #EAEAEA;
// 	margin-top: 46px;
// 	padding: 30px;
// 	box-sizing: border-box;
// }

.checkboxClass {
	width: 20px;
	height: 20px;
}

.checkboxClass /deep/.el-checkbox__input {
	width: 20px;
	height: 20px;
}

.checkboxClass /deep/.el-checkbox__inner {
	width: 20px;
	height: 20px;
}

.understoodDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 20px;
}

.iKonwDiv {
	margin-left: 9px;
}

.accountNumber {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 20px;
	// margin-top: 55px;
	font-size: 14px;
	font-weight: 300;
	color: #000000;
	// line-height: 55px;

}

.accountNumber div:nth-child(2) {
	margin-left: 20px;
}

.bankImage {
	width: 128px;
	height: 37px;
	margin-right: 75px;
	margin-left: 28px;
}

.understoodDiv /deep/.is-checked {
	width: 20px !important;
	height: 20px !important;
}

.understoodDiv /deep/.el-checkbox__inner {
	width: 20px !important;
	height: 20px !important;
}

.understoodDiv /deep/.el-checkbox__input {
	width: 20px !important;
	height: 20px !important;
}

.el-checkbox__original {
	width: 20px !important;
	height: 20px !important;
}

/deep/.el-checkbox__inner::after {
	width: 9px !important;
	height: 9px !important;
}

.companySpan {
	margin-left: 20px;
}

.tableClass {
	// width:100px;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: 39px;
	// margin:30px 0;
}

.tableClass div {
	marign-left: 20px !important;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	font-size: 14px;
	margin-right: 103px;
	line-height: 14px;
}

.tableClass span {
	margin-left: 10px;
	color: #F88700;
	font-size: 24px;
	font-weight: bold;
	color: #F36C09;
	line-height: 30px;
}

.tableClass img {
	width: 22px;
	height: 22px;
	margin-right: 8px;

}

.accountNumberFDiv {
	width: 1086px;
	height: 277px;
	background: #FFFFFF;
	// box-shadow:4px 4px 20px 20px red;
	border: 2px solid #f0f0f0;
	margin-top: 54px;
	padding: 32px 0px 43px 36px;
	box-sizing: border-box;
}



.bankView {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	line-height: 30px;
}

.bankView-smallView {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	margin-left: 0;
}

.blanceClass {
	font-size: 24px;
	font-weight: bold;
	color: #F36C09;
	line-height: 30px;
}

.accountNumberView {
	width: 267px;
	height: 45px;
	background: #F5F5F6;
	font-size: 14px;
	font-weight: 300;
	color: #000000;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

}

.accountNumberFDivNew {
	width: 1086px;
	height: 277px;
	background: #FFFFFF;
	// box-shadow:4px 4px 20px 20px red;
	border: 2px solid #f0f0f0;
	margin-top: 54px;
	padding: 32px 0px 43px 36px;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding-left: 55px;
	box-sizing: border-box;
}

.noHaveBankImage {
	width: 695px;
	height: 255px;

}</style>
